import React from 'react'
import NavBar from '../../navBar/nB-index'

function CreativeRetrofit() {

  return (
    <>
      <div className='section-container'>
        <NavBar />
        <section className='sections s-one'>  
          <h1>BITCH</h1>
        </section>
        <section className='sections s-two'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-three'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-four'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-five'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-six'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-seven'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-eight'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-nine'>
          <h1>BITCH</h1>
        </section>
        {/* <section className='sections s-ten'>
          <h1>BITCH</h1>
        </section>
        <section className='sections s-eleven'>
          <h1>BITCH</h1>
        </section> */}
      </div>
    </>
  )

}
export default CreativeRetrofit