import React from 'react'
import MainIntro from '../../images/landingPageImages/intro.gif'

function IntroSpinner() {

  return (

    <>
      <div className="spinner-container">
        <img src={MainIntro}/>
      </div>
    </>
  )


}
export default IntroSpinner
