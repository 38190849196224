import React from 'react' // import NavBar from '../navBar/nB-index'

function UmTeam(props) {
  return (
    <>
      <div style={{
        backgroundImage: `url(${props.content})`,
      }}
      className='umoja-container'>

      </div>
    </>
  )
}
export default UmTeam