// import React from 'react'

import garden from '../../../images/landingPageImages/mainImages/garden.jpeg'

export const moreInfoData = [
  {
    title: 'Home',
    image: garden,
    cName: 'moreInfo-text',
  },
  {
    title: 'Home',
    image: garden,
    cName: 'moreInfo-text',
  },
  {
    title: 'Home',
    image: garden,
    cName: 'moreInfo-text',
  }
]